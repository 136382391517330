<template>
    <div>
        <ul>
            <li><router-link to="textDiff" active-class="active-link">Code Diff</router-link></li>
        </ul>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'DiffCheck',
};
</script>

<style>
.active-link {
    font-weight: bold;
    color: blue;
    /* Change to your desired color */
    text-decoration: underline;
    /* For underlining the link */
}
</style>