<template>
    <div class="editor">
        <span>Input</span>
        <textarea v-model="jsonInput" @input="formatJson" placeholder="Paste your JSON here..."></textarea>
        <div v-if="jsonError" style="color: red;">{{ jsonError }}</div>
    </div>
    <Output :jsonData="jsonOutput" />
</template>

<script>
import Output from './JsonOutput.vue';
export default {
    components: {
        Output,
    },
    data() {
        return {
            jsonInput: '',
            jsonOutput: '',
            jsonError: ''
        }
    },
    methods: {
        formatJson() {
            try {
                const parsedJson = JSON.parse(this.jsonInput);
                this.jsonOutput = JSON.stringify(parsedJson, null, 4);
                this.jsonError = '';
            } catch (e) {
                this.jsonError = 'Invalid JSON: ' + e.message;
                this.jsonOutput = '';
            }
        }
    }
}

</script>

<style scoped>
.editor {
    float: left;
    width: 48%;
    border-radius: 8px;
    padding: 10px;

    margin: auto;
    white-space: pre-wrap;
    font-family: monospace;
}

textarea {
    height: calc(100vh - 20vh);
    margin-top: 10px;
    width: 100%;

    margin-bottom: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
}
</style>