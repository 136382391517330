<template>
    <div :style="{ backgroundColor: backgroundColor }" class="pomodoro-timer" @mouseenter="toggleBackground">
        <div class="timer-display">
            <h1 :style="{ color: fontColor }">{{ formattedTime }}</h1>
        </div>
        <div class="time-controls" v-if="backgroundColor === 'white'">
            <button @click="addTime(60)">+1:00</button>
            <button @click="addTime(300)">+5:00</button>
        </div>
        <div class="control-buttons" v-if="backgroundColor === 'white'">
            <button @click="startTimer">{{ isActive ? 'pause' : 'start' }}</button>
            <button @click="resetTimer">reset</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            timeLeft: 15 * 60, // 初始15分钟
            timer: null,
            isActive: false,
            backgroundColor: 'white',
            fontColor: 'black',
        };
    },
    methods: {
        startTimer() {
            if (this.isActive) {
                clearInterval(this.timer);
                this.isActive = false;
            } else {
                this.isActive = true;
                this.timer = setInterval(() => {
                    if (this.timeLeft > 0) {
                        this.timeLeft--;
                    } else {
                        clearInterval(this.timer);
                        this.isActive = false;
                    }
                }, 1000);
            }
        },
        resetTimer() {
            clearInterval(this.timer);
            this.timeLeft = 25 * 60;
            this.isActive = false;
        },
        addTime(seconds) {
            this.timeLeft += seconds;
        },
        toggleBackground() {
            this.backgroundColor = this.backgroundColor === 'white' ? 'black' : 'white';
            this.fontColor = this.backgroundColor === 'white' ? 'black' : 'white';
        },
    },
    computed: {
        formattedTime() {
            const minutes = Math.floor(this.timeLeft / 60);
            const seconds = this.timeLeft % 60;
            return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        },
    },
};
</script>

<style scoped>
.pomodoro-timer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.timer-display {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.time-controls button {
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
}

.control-buttons button {
    margin: 0.5rem;
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 50px;
}
</style>