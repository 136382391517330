<template>
    <div class="login-container">
        <h2>Login</h2>
        <form @submit.prevent="handleLogin">
            <div>
                <label for="username">Username:</label>
                <input type="text" v-model="username" id="username" required />
            </div>
            <div>
                <label for="password">Password:</label>
                <input type="password" v-model="password" id="password" required />
            </div>
            <button type="submit">Login</button>
        </form>
        <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
    data() {
        return {
            username: "",
            password: "",
            errorMessage: ""
        };
    },
    methods: {
        handleLogin() {
            // Example of a simple login check
            if (this.username === "admin" && this.password === "123456") {
                // Set a cookie on successful login
                Cookies.set('username', this.username, { expires: 1 }); // expires in 1 day
                alert("Login successful!");
                // Add logic to redirect or fetch user data
            } else {
                this.errorMessage = "Invalid username or password.";
            }
        }
    }
};
</script>

<style scoped>
.login-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.error {
    color: red;
}
</style>