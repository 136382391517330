<template>
    <div class="container">
        <div class="message-container">
            <img class="about-image" src="./../assets/aboutUS.png" alt="about us">
            <span class="message">{{ msg }}</span>
        </div>
    </div>
    <!-- <KvCache /> -->
    <!-- <FormateDate /> -->
</template>

<script>
// import KvCache from './database/kvCache.vue';
// import FormateDate from './demo/formateDate.vue';

export default {
    components: {
        // KvCache,
        // FormateDate,
    },
    data() {
        return {
            msg: "Science and technology is the first productive force",
        }
    }
}
</script>

<style scoped>
.container {
    display: flex;
}

.message-container {
    border-radius: 8px;
    padding: 20px;
}

.message {
    font-size: 1.5em;
    color: #333;
}

.about-image {
    display: flex;
    width: 80%;
    /* Use 80% of the container's width */
    max-width: 400px;
    /* Set a maximum width */
    height: auto;
    /* Maintain aspect ratio */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}
</style>

<!-- road is long ,go slowly -->