<template>
    <div class="editor">
        <textarea v-model="markdown" placeholder="Enter Markdown content"></textarea>
    </div>
    <button @click="convertToPdf">Convert to PDF</button>
    <div class="md-viewer" v-html="renderedMarkdown" ref="content"></div>
</template>

<script>
import { marked } from 'marked';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css'; // You can choose any style you prefer

export default {
    data() {
        return {
            markdown: '',
            renderedMarkdown: ''
        };
    },
    methods: {
        convertToPdf() {
            const pdf = new jsPDF();
            html2canvas(this.$refs.content, { scrollY: -window.scrollY }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                this.addImageToPdf(pdf, imgData, canvas);
            }).catch(err => {
                console.error('Failed to convert to PDF: ', err);
            });
        },
        addImageToPdf(pdf, imgData, canvas) {
            const imgWidth = 190; // Set width of the image
            const pageHeight = 295; // A4 page height in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;

            let position = 0;

            while (heightLeft >= 0) {
                pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                if (heightLeft >= 0) {
                    pdf.addPage();
                }

                position = heightLeft <= 0 ? 0 : heightLeft - imgHeight; // Prepare position for next image
            }

            pdf.save('document.pdf');
        },
        highlightCode() {
            const blocks = this.$refs.content.querySelectorAll('pre code');
            blocks.forEach(block => hljs.highlightElement(block));
        }
    },
    watch: {
        markdown(newMarkdown) {
            this.renderedMarkdown = marked(newMarkdown);
            this.$nextTick(this.highlightCode);
        }
    }
};
</script>

<style scoped>
button {
    margin-left: 10px;
    height: 20px;
}

.editor {
    float: left;
    width: 48%;
    border-radius: 8px;
    padding: 10px;
    margin: auto;
    white-space: pre-wrap;
    font-family: monospace;
}

textarea {
    height: calc(100vh - 10vh);
    margin: 10px 0;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #ccc;
}

.md-viewer {
    float: right;
    width: 48%;
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 10px;
    height: calc(100vh - 13vh);
    overflow-y: auto;
    font-family: monospace, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
</style>
