// src/utils.js

export function formatDate(date) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}



export async function pixelLog(param, param2) {
    // console.log("pixel log:", param, param2);
    let kvUrl = 'https://aicache.gson.workers.dev'
    try {
        const response = await fetch(`${kvUrl}/?op=s&key=${param}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/text/plain',
                'Authorization': 'abc123',
            },
            body: param2
        });
        if (response.ok) {
            console.error('Data stored successfully');
        } else {
            console.error('Failed to store data');
        }
    } catch (error) {
        console.error('Error storing KV data:', error);
    }
}