<template>
    <div class="book-list">
        <h1 class="title">Recommended Books</h1>
        <div class="books">
            <div class="book-card" v-for="book in books" :key="book.id">
                <!-- <img src="./../../assets/logo.png" alt="Book Cover" class="book-cover"> -->
                <img :src="book.coverImage" alt="Book Cover" class="book-cover">
                <!-- <img class="about-image" src="./../assets/aboutUS.png" alt="about us"> -->
                <div class="book-info">
                    <h2 class="book-title">{{ book.title }}</h2>
                    <p class="book-author">{{ book.author }}</p>
                    <p class="book-description">{{ book.description }}</p>
                    <button @click="openPdf(book.pdfLink)" class="read-more">Read PDF</button>
                </div>
            </div>
        </div>

        <!-- PDF Reader Modal -->
        <div v-if="isModalOpen" class="modal" @click="closeModal">
            <iframe :src="currentPdf" class="pdf-viewer"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            books: [
                {
                    id: 1,
                    title: "PROGRAMMING WITH ABSTRACT DATA TYPES",
                    author: "None",
                    description: "A classic novel set in the Roaring Twenties.",
                    coverImage: require('./../../assets/logo.png'),
                    pdfLink: "http://localhost:8081/ebook/PROGRAMMING WITH ABSTRACT DATA TYPES.pdf"
                },
                {
                    id: 2,
                    title: "UML Entity Relationship Modeling",
                    author: "None",
                    description: "A dystopian novel about totalitarianism.",
                    coverImage: require('./../../assets/logo.png'),
                    pdfLink: "http://localhost:8081/ebook/UMLEntityRelationshipModeling.pdf"
                },
                // Add more book objects as needed
            ],
            isModalOpen: false,
            currentPdf: ''
        };
    },
    methods: {
        openPdf(pdfLink) {
            this.currentPdf = pdfLink;
            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
            this.currentPdf = ''; // Reset current PDF
        }
    }
}
</script>

<style scoped>
.book-list {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
}

.title {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
}

.books {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 8px;
}

.book-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.book-cover {
    width: 100%;
    height: auto;
}

.book-info {
    padding: 10px;
}

.book-title {
    font-size: 1em;
    margin: 0;
}

.book-author {
    color: gray;
}

.book-description {
    font-size: 0.9em;
    margin: 10px 0;
}

.read-more {
    color: #007BFF;
    cursor: pointer;
    border: none;
    background: none;
}

.read-more:hover {
    text-decoration: underline;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.pdf-viewer {
    width: 100%;
    height: 100%;
    border: none;
}
</style>
